import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import ContactUS from '../../ContactUS';
// import ForgetPasswordComponent from './ForgetPassword';

export default function HomeScreenLogin() {
	const [userName, setUserName] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [PhoneNumber, setPhoneNumer] = React.useState("")

	const [reload, setReload] = React.useState(false)
	const [passwordShow, setPasswordShow] = React.useState(false)
	const [AlertShow, setAlertShow] = React.useState(false)
	const [Alertcomp, setAlertcomp] = React.useState("")
	const [Register, setRegister] = React.useState(false)
	const [loginSuccess, setLoginSuccess] = React.useState(false)
	const [forgetPasswordPressed, setForgetPasswordPressed] = React.useState(false)


	return (
		<div style={{ backgroundColor: "#f2f3f2", minHeight: "100vh", width: "100vw", overflow: 'hidden' }}>
			<div className='row'>
				<div className="row">
					{/* {loginSuccess &&
          <Navigate to="/" />
        } */}
					<div className="col-lg-2 col-1"></div>
					<div className="col-lg-8 col-10 shadow signin-container" style={{ backgroundColor: "#fff" }}>
						<div className="py-5">
							<div style={{ display: 'flex', justifyContent: 'center',padding:"0 0 50px" }}>
								<img
									src={signInImage}
									height={"100px"}
								/>
							</div>
							<div className='row'>
								<div className="col-1" />
								<div className="col-4">
									<Link className="Link" to={slugs.demo_exam}>
										<div className='shadow form-view center' style={{ cursor: "pointer" }}
										>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/5231719.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<span style={{ fontSize: "20px", fontWeight: "bold", margin: "10px" }}>Demo Exam</span>
										</div>
									</Link>
								</div>
								<div className="col-2" />
								<div className="col-4">
									<Link className="Link" to={slugs.classroom}>
										<div className='shadow form-view center'>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/3475156.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<span style={{ fontSize: "20px", fontWeight: "bold", margin: "10px" }}>Class Room</span>
										</div>
									</Link>
								</div>


							</div>
							<div className='row mt-5'>
								<div className="col-1" />
								<div className="col-4">
									<a className="Link" href={"https://goodwillkorean.com"}>
										<div className='shadow form-view center'>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/home.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<span style={{ fontSize: "20px", fontWeight: "bold", margin: "10px" }}>Home</span>
										</div>
									</a>
								</div>
								<div className="col-2" />
								<div className="col-4">
									<Link className="Link" to={"/login"}>
										<div className='shadow form-view center'>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/login.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<span style={{ fontSize: "20px", fontWeight: "bold", margin: "10px" }}>Login</span>
										</div>
									</Link>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <ContactUS/> */}
			{reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}

		</div>
	)
}